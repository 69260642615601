<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div>
    <div class="row mt-5">
      <div class="text-start col-6">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_movimenti_economato"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          filtrata
        </button>
      </div>
      <ModalEsportaMovimentiEconomato />
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="data.length == 0 && loaded && (status == 200 || status == 204)"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="data"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRows"
        @current-change="setCurrentPage"
      >
        <template v-slot:cell-numero="{ row: data }"
          ><div class="">
            {{ data.numero }}
          </div>
        </template>
        <template v-slot:cell-causale="{ row: data }"
          ><div class="">
            {{ data.causale }}
          </div>
        </template>
        <template v-slot:cell-soggetto="{ row: data }"
          ><div class="tab-long">
            {{ data.soggetto }}
          </div>
        </template>
        <template v-slot:cell-descrizione="{ row: data }"
          ><div class="tab-long">
            {{ data.descrizione }}
          </div>
        </template>
        <template v-slot:cell-importo="{ row: data }"
          ><div class="text-end">
            {{ data.importo.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell-data_inserimento="{ row: data }"
          ><div class="text-center">
            {{ data.data_inserimento }}
          </div>
        </template>
        <template v-slot:cell-data_approvazione="{ row: data }"
          ><div class="text-center">
            {{ data.data_approvazione }}
          </div>
        </template>
        <template v-slot:cell-quote="{ row: data }"
          ><div class="">
            {{ data.quote }}
          </div>
        </template>
        <template v-slot:cell-options="{ row: data }"
          ><div v-if="!readOnly && data.stornabile" class="">
            <button
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <a href="#" @click="delMovMovimento(data.id_movimento)">
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-trash text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Storna</span>
                    </div>
                  </div>
                </li></a
              >
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";

import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import { deleteMovMovimento } from "@/requests/economatoPagamentiRequests";

import Loading from "vue3-loading-overlay";
import isEnabled from "@/composables/isEnabled.js";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import ModalEsportaMovimentiEconomato from "./ModalEsportaMovimentiEconomato.vue";

export default {
  name: "table-movimenti-economato",
  components: { Datatable, Loading, ModalEsportaMovimentiEconomato },
  emits: ["getEconomatoList", "resetFilters"],
  props: {},

  setup(props, { emit }) {
    const tableHeader = ref([
      {
        name: "Numero",
        key: "numero",
      },
      {
        name: "Causale",
        key: "causale",
      },
      {
        name: "Soggetto",
        key: "soggetto",
      },
      {
        name: "Descrizione",
        key: "descrizione",
      },
      {
        name: "Importo",
        key: "importo",
        align: "end",
      },
      {
        name: "Data inserimento",
        key: "data_inserimento",
        align: "center",
      },
      {
        name: "Data approvazione",
        key: "data_approvazione",
        align: "center",
      },
      {
        name: "Quote",
        key: "quote",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const store = useStore();

    const setFetchRows = (e) => {
      store.commit("setFetchRowsMovimenti", e);
      emit("getEconomatoList");
    };
    const setCurrentPage = (page) => {
      store.commit("setCurrentPageMovimenti", page);
      emit("getEconomatoList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnMovimenti", columnName);
      store.commit("setSortOrderMovimenti", order);
      emit("getEconomatoList");
    };

    const isLoading = ref(false);

    const delMovMovimento = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Il movimento verrà stornato definitivamente.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Storna",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteMovMovimento(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Movimento stornato correttamente");
              emit("getEconomatoList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const esportaLista = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    return {
      tableHeader,
      delMovMovimento,

      setFetchRows,
      setCurrentPage,
      isEnabled,
      setSortOrderColumn,

      data: computed(() =>
        store.getters.getStateFromName("resultseconomato_movimenti_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedeconomato_movimenti_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordeconomato_movimenti_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuseconomato_movimenti_list")
      ),
      currentPage: computed(() => store.getters.currentPageMovimenti),
      rowsToSkip: computed(() => store.getters.rowsToSkipMovimenti),
      fetchRows: computed(() => store.getters.fetchRowsMovimenti),
      sortColumn: computed(() => store.getters.sortColumnMovimenti),
      sortOrder: computed(() => store.getters.sortOrderMovimenti),
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      esportaLista,
    };
  },
};
</script>

<style scoped></style>
