import services from "../axios/dbManag";

export const approvaMovPagamento = (
  id_Movimento,
  id_Persona,
  id_Societa,
  anno,
  data_Competenza
) => {
  return services.dbManag
    .post("/economato/movimenti/pagamento/approva", {
      id_Movimento,
      id_Persona,
      id_Societa,
      anno,
      data_Competenza,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

// storna pagamento in casi particolari
export const deleteMovPagamento = (
  id_Movimento,
  id_Persona,
  id_Societa,
  anno
) => {
  return services.dbManag
    .post("/economato/movimenti/pagamento/del", {
      id_Movimento,
      id_Persona,
      id_Societa,
      anno,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteMovMovimento = (id_Movimento) => {
  return services.dbManag
    .post("/economato/movimenti/movimento/del", {
      id_Movimento,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
