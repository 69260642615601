<template>
  <div>
    <FilterMovimentiEconomato
      @getEconomatoList="getEconomatoListResetPage"
      @resetFilters="resetFilters"
    />
    <TableMovimentiEconomato
      @getEconomatoList="getEconomatoList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import FilterMovimentiEconomato from "@/components/components-fit/gestione/economato/FiltriMovimentiEconomato.vue";
import TableMovimentiEconomato from "@/components/components-fit/gestione/economato/TableMovimentiEconomato.vue";
import { trimInput } from "@/composables/trimInput";

export default defineComponent({
  name: "movimenti-economato",
  components: {
    FilterMovimentiEconomato,
    TableMovimentiEconomato,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Economato");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);
    const idComitatoRegionaleMovimenti = computed(
      () => store.getters.idComitatoRegionaleMovimenti
    );
    const dataInsDaMovimenti = computed(() => store.getters.dataInsDaMovimenti);
    const dataInsAMovimenti = computed(() => store.getters.dataInsAMovimenti);
    const dataApprDaMovimenti = computed(
      () => store.getters.dataApprDaMovimenti
    );
    const dataApprAMovimenti = computed(() => store.getters.dataApprAMovimenti);
    const idFamigliaMovimentoMovimenti = computed(
      () => store.getters.idFamigliaMovimentoMovimenti
    );
    const idMovimentoManualeMovimenti = computed(
      () => store.getters.idMovimentoManualeMovimenti
    );
    const tipoCredDebMovimenti = computed(
      () => store.getters.tipoCredDebMovimenti
    );
    const idSocietaMovimenti = computed(() => store.getters.idSocietaMovimenti);
    const idPersonaMovimenti = computed(
      () => store.getters.idTesseratoMovimenti
    );
    const numeroMovimenti = computed(() => store.getters.numeroMovimenti);
    const meseApprovazioneMovimenti = computed(
      () => store.getters.meseApprovazioneMovimenti
    );
    const causaleMovimenti = computed(() => store.getters.causaleMovimenti);
    const approvatoMovimenti = computed(() => store.getters.approvatoMovimenti);

    const rowsToSkipMovimenti = computed(
      () => store.getters.rowsToSkipMovimenti
    );
    const fetchRowsMovimenti = computed(() => store.getters.fetchRowsMovimenti);
    const sortColumnMovimenti = computed(
      () => store.getters.sortColumnMovimenti
    );
    const sortOrderMovimenti = computed(() => store.getters.sortOrderMovimenti);

    const getEconomatoList = () => {
      const localKeys = {
        stagione: anno.value,
        id_Comitato: idComitatoRegionaleMovimenti.value,
        data_Ins_Da: dataInsDaMovimenti.value,
        data_Ins_A: dataInsAMovimenti.value,
        data_Appr_Da: dataApprDaMovimenti.value,
        data_Appr_A: dataApprAMovimenti.value,
        id_Mov_Famiglia: idFamigliaMovimentoMovimenti.value,
        id_Mov_Manuale: idMovimentoManualeMovimenti.value,
        tipocreddeb: tipoCredDebMovimenti.value,
        id_Societa: idSocietaMovimenti.value,
        id_Persona: idPersonaMovimenti.value,
        numero: trimInput(numeroMovimenti.value),
        mese: meseApprovazioneMovimenti.value,
        causale: trimInput(causaleMovimenti.value),
        approvato: approvatoMovimenti.value,
        rowstoskip: rowsToSkipMovimenti.value,
        fetchrows: fetchRowsMovimenti.value,
        sortcolumn: sortColumnMovimenti.value,
        sortorder: sortOrderMovimenti.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.ECONOMATO_MOVIMENTI_LIST,
        itemName: "economato_movimenti_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersMovimenti");
      getEconomatoList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedeconomato_movimenti_list")
    );
    const getEconomatoListResetPage = () => {
      if (!loaded.value) return;
      store.commit("resetRowsToSkipMovimenti");
      getEconomatoList();
    };
    getEconomatoList();

    return {
      getEconomatoList,
      resetFilters,
      getEconomatoListResetPage,
    };
  },
});
</script>
